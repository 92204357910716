/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import Whatshappening from "./HomeSubCompo/Whatshappening";
import ProductDetail from "../Products/ProductDetail";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";

import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  dineinId,
  cateringId,
  reservationId,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
  GET_STATIC_BLOCK,
  GET_TESTIMONIALS,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  showAlert,
  stripslashes
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import carImg from "../../common/images/van-car.png";
import catImg from "../../common/images/menu-cat-background.jpg";
import reservationImg from "../../common/images/calander.png";

var Parser = require("html-react-parser");

class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      homeorderInfo: "",
      homesubscribe: "",
      staticBlocks: [],
      testimonialsBlks: "",
      testimonials:[], 
      alertMessageBlks: '',
      testimonialpath:[], 
      newsLetterEmail: "",
    };

    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
    this.props.getTestimonials();
  }

  componentWillReceiveProps(PropsData) {
    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.testimonials !== undefined && PropsData.testimonials !== this.state.testimonials) {

      this.setState({
        testimonials: PropsData.testimonials,
        testimonialpath: PropsData.testimonialpath,
      });

    }
    if (PropsData.staticblack !== this.state.staticBlocks) {
      var testimonialsPas = "";
      var alertMessage = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "testimonials") {
            testimonialsPas = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "alert-message") {
            alertMessage = data.staticblocks_description;
            return "";
          }
        });
      }
      testimonialsPas =
        testimonialsPas !== "" ? Parser(testimonialsPas) : testimonialsPas;
      alertMessage =
        alertMessage !== "" ? (alertMessage) : alertMessage;
      this.setState({
        staticBlocks: PropsData.staticblack,
        testimonialsBlks: testimonialsPas,
        alertMessageBlks: alertMessage,
      });
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined && availability != dineinId && availability != cateringId
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {

        if(typeof cookie.load('alertopened') == 'undefined'){
          setTimeout(function(){
          cookie.save("alertopened", "Yes", { path: "/" });
          showAlert("Welcome", (this.state.alertMessageBlks));
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          }.bind(this),1000)
        }


    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
    if (field === "homeorderInfo" && value !== "") {
      this.setState({ homeorderInfo: value });
    }
    if (field === "homesubscribe" && value !== "") {
      this.setState({ homesubscribe: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          console.log(
            settingsArr[0].result_set.client_promocode_options,
            "settingsArr[0].result_set.client_promocode_options"
          );
          if (settingsArr[0].result_set.client_promocode_options === "1") {
            showPromoPopup =
              settingsArr[0].result_set.client_promocode_options === "1"
                ? "yes"
                : "";
          }
        }
      }
    }

    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }
    if (showNormalPopup === "yes") {
      if (
        trgType === "loading" &&
        otherPageActTrigger === "no" &&
        this.state.normalpopup_status === "yes" &&
        Object.keys(this.state.normalpopupdata).length > 0 &&
        cookie.load("promoPopupTrigger") !== "Yes"
      ) {
        var normalpopupIds = cookie.load("normalpopupIds");
        var normalpopupIdsNew =
          normalpopupIds != "" && normalpopupIds != undefined
            ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
            : this.state.normalpopupdata.normalpopup_id;
        var normalpopupIdArr = [];
        normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
        cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
        var $_this_rec = this;
        $.magnificPopup.open({
          items: {
            src: "#normal-popup",
          },
          type: "inline",
          midClick: true,
          closeOnBgClick: false,
          callbacks: {
            close: function () {
              $_this_rec.normalPopupUpdate();
            },
          },
        });
      }
    }
    if (showPromoPopup === "yes") {
      if (
        (cookie.load("promoPopupTrigger") === "Yes" ||
          (otherPageActTrigger === "no" &&
            this.state.normalpopup_status === "no" &&
            Object.keys(this.state.normalpopupdata).length === 0)) &&
        cookie.load("mailpopopuptrg") !== "yes"
      ) {
        cookie.save("mailpopopuptrg", "yes", { path: "/" });
        cookie.remove("promoPopupTrigger", { path: "/" });
        $.magnificPopup.open({
          items: {
            src: "#promo-check-popup",
          },
          type: "inline",
          midClick: true,
          closeOnBgClick: false,
        });
      }
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of Westlake Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined && availability != dineinId && availability != cateringId
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      availability != dineinId &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      $.magnificPopup.close();
      this.props.history.push("/products");
      return false;
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === dineinId) {
      popupIdtxt = "#comingsoon-popup";
    } else if (availability === cateringId) {
      popupIdtxt = "#comingsoon-popup";
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      this.props.history.push("/reservation");
      return false;
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkEmail() {
    var magnfPopup = $.magnificPopup.instance;
    if (this.state.newsLetterEmail !== "") {
      var qs = require("qs");

      var postObject = {
        app_id: appId,
        email: this.state.newsLetterEmail,
      };

      axios
        .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
        .then((res) => {
          // var magnfPopup = $.magnificPopup.instance;
          if (res.data.status === "ok") {
            showAlert("Success", "Newsletter Subscribed", magnfPopup);
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          } else {
            showAlert("Error", "Enter a valid email", magnfPopup);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    } else {
      // showAlert("Error", "Enter a valid email", magnfPopup);
      // showCustomAlert("Error", "Enter a valid email");
      showAlert("Error", "Please enter your email.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  handleChangeemail(event) {
    this.setState({ newsLetterEmail: event.target.value });
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  availabilityList() {
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      var settingsRes = settingsArr[0].result_set.availability;
      if (settingsRes.length > 0) {
        return settingsRes.map((item, index) => {
          return (
            <>
                {(() => {
                  if (item.availability_id === deliveryId) {
                    return (
                      <a
                        href={void 0}
                        className="ord_btn"
                        onClick={this.chooseAvailabilityFun.bind(this, deliveryId)}
                      >
                        Delivery
                      </a>
                    );
                  } else if (item.availability_id === pickupId) {
                    return (
                      <a
                        href={void 0}
                        className="ord_btn"
                        onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      >
                        Pickup
                      </a>
                    );
                  } else if (item.availability_id === dineinId) {
                    return (
                      <a
                        href={void 0}
                        className="ord_btn"
                        onClick={this.chooseAvailabilityFun.bind(this, dineinId)}
                      >
                        Dine In
                      </a>
                    );
                  } else if (item.availability_id === reservationId) {
                    return (
                       <a
                        href={void 0}
                        className="ord_btn"
                        onClick={this.chooseAvailabilityFun.bind(this, reservationId)}
                      >
                        Reservation
                      </a>
                    );
                  } else if (item.availability_id === cateringId) {
                    return (
                      <a
                        href={void 0}
                        className="ord_btn"
                        onClick={this.chooseAvailabilityFun.bind(this, cateringId)}
                      >
                        Catering
                      </a>
                    );
                  }
                })()}
            </>
          );
        });
      }
    }
  }


  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          if (settingsArr[0].result_set.client_promocode_options === "1") {
            showPromoPopup =
              settingsArr[0].result_set.client_promocode_options === "1"
                ? "yes"
                : "";
          }
        }
      }
    }

    if(Object.keys(this.state.testimonials).length <= 3){
    var slidelength = Object.keys(this.state.testimonials).length;
    }else{
    var slidelength = 3;
    }
    var settingstext = {
      infinite: true,
      slidesToShow: slidelength,
      slidesToScroll: slidelength,
      centerMode: true,
      centerPadding: "60px",
      dots: true,
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Home banner section */}
        <section className="white-home">
          <HomeBanner />
        </section>
        <section className="home-main-order">
          <div className="home-main-flex">
            {this.state.homeorderInfo}
            <div className="order-grp">
                      {this.availabilityList()}
            </div>
            <div className="order_dlivy">
              <img src={carImg} />
            </div>
          </div>
        </section>

        <Whatshappening />

        <FeaturedProducts sateValChange={this.sateValChange} />

        {/*this.props.navigateMenu.length > 0 && (
          <section className="menu-cat">
            <h3 className="promo-title">Menu Categories</h3>
            <div className="menu-cat-container">
              {this.props.navigateMenu.map((item, index) => {
                return (
                  <Link
                    to={"/products/category/" + item.pro_cate_slug}
                    key={index}
                  >
                    <div className="menu-cat-item">
                      <div className="menu-cat-over">
                        <h5 className="menu-sub-title">
                          {item.menu_custom_title}
                        </h5>
                        {item.pro_cate_image !== "" &&
                        item.pro_cate_image !== null ? (
                          <img
                            src={
                              this.props.navigateCommon.category_image_url +
                              "/" +
                              item.pro_cate_image
                            }
                          />
                        ) : (
                          <img src={catImg} />
                        )}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </section>
        )*/}

        {Object.keys(this.state.testimonials).length > 0 &&
          <section className="client-sec">
            <div className="client_riv">
              <div className="client_riv_innr">
                <div className="client_riv_title">
                  <h3 className="promo-title">What People Say...</h3>
                </div>
                <div className="client_riv_slider">
                  <Slider {...settingstext}>
                    {Object.keys(this.state.testimonials).length && this.state.testimonials.map((testimonial, index) => {
                    return (
                    <div class="client_riv_item" key={index+1000}>
                      <p>{stripslashes(testimonial.testimonial_description)}</p>
                      {/*<div className="testi-author-img"><img src={this.state.testimonialpath.image_source+testimonial.testimonial_image} /></div>*/}
                      <h5>{testimonial.testimonial_tittle}</h5>
                      <p class="client_riv_stitle">
                        {testimonial.testimonial_company}</p>
                    </div>
                     );
                    })}  
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        }

        <section className="subs-news">
          <div className="container">
            {this.state.homesubscribe}
            <div className="news-form-group">
              <input
                type="email"
                onChange={this.handleChangeemail.bind(this)}
                placeholder="Insert your email"
                className="form-control"
                value={this.state.newsLetterEmail}
              />
              <button
                className="news-btn button news-letter-subscribe"
                onClick={this.checkEmail.bind(this)}
              >
                Sign Up
              </button>
            </div>
          </div>
        </section>

        {/* Footer section */}
        <Footer />
        <ProductDetail
          productState={this.state}
          sateValChange={this.sateValChange}
        />
        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*Alert popup Start*/}
        <div
          id="alert-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.alertMessageBlks != "" && (
                <div className="normal_popup_cont">
                  {(this.state.alertMessageBlks)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}
        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  var testimonials = Array();
  var testimonialpath = Array();

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  if (Object.keys(state.testimonials).length > 0) {
    testimonials  = state.testimonials[0].result_set;
    testimonialpath = state.testimonials[0].common;
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    testimonials: testimonials, 
    testimonialpath: testimonialpath,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getTestimonials: () => {
      dispatch({ type: GET_TESTIMONIALS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
