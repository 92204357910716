/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GET_REQUESTPAGEDATA,GET_RES_ORDER_SUBMIT} from '../../actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import moment from 'moment';
import {appId,apiUrl,reservationId} from "../Helpers/Config";
import Axios from "axios";
var base64 = require("base-64");
var qs = require("qs");

import tickImg from "../../common/images/tick.png";
import rejectImg from "../../common/images/worried.png";


class Reservationsuggestion extends Component {

    constructor(props) {
        super(props);
	   this.state = {message:'',reservation_data:[]};
        cookie.save("orderTabs", 'reservation', { path: "/" });	
    }
		
	componentDidMount(){

		let subreservationId =
      typeof this.props.match.params.resid != "undefined"
        ? this.props.match.params.resid
        : "";
        let acceptReject =
      typeof this.props.match.params.acceptreject != "undefined"
        ? this.props.match.params.acceptreject
        : "";
    $(".dvLoadrCls").show();

		var postObject = {};
        postObject = {
          app_id: appId,
          sug_reser_id: base64.decode(subreservationId)
        };

        let ajaxUrl = '';
        if(acceptReject === 'accept'){
	         ajaxUrl =  apiUrl + "reservation/reservation_suggest_accept";
        }
        if(acceptReject === 'reject'){
	         ajaxUrl =  apiUrl + "reservation/reservation_suggest_reject";
        }

        if(ajaxUrl){
	        Axios.post(
	          ajaxUrl,
	          qs.stringify(postObject)
	        ).then((res) => {
		      $(".dvLoadrCls").fadeOut(500);

	          if (res.data.status === "success") {
        		if(acceptReject == 'accept'){
	          		this.setState({'acceptReject': 'success'});
	          		this.setState({'message': 'Your Reservation has been confirmed'});
	          		this.setState({'reservation_data': res.data.result_set[0]});
	          		this.setState({'outletName': res.data.outlet_name});
	          	}
        		if(acceptReject == 'reject'){
	          		this.setState({'acceptReject': 'error'});
	          		this.setState({'message': 'Reservation cancelled'})
	          	}
	          } else if (res.data.status === "error") {
	          		this.setState({'acceptReject': 'error'});
	          		this.setState({'message': 'Your Reservation already confirmed/rejected'})
	          }
	        });
    	}
		
	}
	
  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
    if (field === "resetProduct" && value !== "") {
      this.setState({
        viewProductSlug: "",
      });
    }
  };
	

    render() {
		 
	    let res_date = this.state.reservation_data.reservation_date;
	    let res_time = moment(new Date()).format("YYYY-MM-DD ")+this.state.reservation_data.reservation_start_time;
		return (<div>
			<div className="container common-top-div catering-thankyou-page">
				<Header
          sateValChange={this.sateValChange}
				 />
				
				<div className="innersection_wrap tnk-you">
					<div className="mainacc_toptext tick">
						    {this.state.acceptReject == 'success' ?(
					        <div><img src={tickImg} /><h2>Thank You</h2></div>):(<div><img src={rejectImg} /></div>)
					        }
							<p>{this.state.message}</p>
					 </div>
					 
					 <div className="thank-order-detaildiv">
						
						{(this.state.acceptReject == 'success' &&
						<div>
						<div className="tnk-detail text-center">
							<h2>YOUR RESERVATION DETAILS</h2>
							<div className="tnk-order">
								<h3>Order No - {this.state.reservation_data.reservation_local_order_id}</h3>
								<p>Order placed at :
                                          {moment(this.state.reservation_data.reservation_created_on).format(
                                            "DD-MM-YYYY hh:mm A"
                                          )}</p>
							</div>
						</div>
						
						<div className="tnk-delivery">
							<div className="delivery-cart-div">
								<div className="cart_row cart-header-first">
									
									<div className="reservation_order_details">
										<div className="reservation_outlet">
											<h4>Order Handling By</h4>
											<p>{this.state.reservation_data.reservation_outlet_name}</p>
										</div><br></br>
										<div className="reservation_user">
											<h4>NUMBER OF PAX</h4>
											<p>{this.state.reservation_data.reservation_no_of_adult_pax} Adult & {this.state.reservation_data.reservation_no_of_children_pax} Child</p>
										</div><br></br>
										<div className="reservation_Date">
											<h4>DATE</h4>
											<p>{moment(res_date).format(
                                            "DD-MM-YYYY"
                                          )}</p>
										</div><br></br>
										<div className="reservation_Time">
											<h4>TIME</h4>
											<p>{moment(res_time).format(
                                            "hh:mm A"
                                          )}</p>
										</div><br></br>
										<div className="reservation_notes">
											<h4>SPECIAL INSTRUCTION</h4>
											<p>{this.state.reservation_data.reservation_specification}</p>
										</div><br></br>
									</div>
									
								</div>
							</div>
						</div>
						</div>
						)}
						<div className="tnk-chk-order">
						<Link className="button" to={"/myorders"}>Check Order Status</Link>
					</div>	
					 </div>
					 
				</div>
				
			</div>
			<Footer />	
          <div id="dvLoading" className="dvLoadrCls"></div>
		</div>);
    }
}




export default (Reservationsuggestion);

 
