import { SET_TESTIMONIALS } from '../actions';

const testimonials = (state = [], action) => {
  switch (action.type) {
	case SET_TESTIMONIALS:
      return [...action.value];  
    default: return state;
  }
}

export default testimonials;
