/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import footerLogo from "../../common/images/foot-logo.png";
import facebook from "../../common/images/facebook.png";
import instagram from "../../common/images/instagram.png";
import youtube from "../../common/images/youtube.png";
import Address from "../../common/images/placeholder.png";
import Telephone from "../../common/images/telephone.png";
import Email from "../../common/images/email.png";
import { GET_STATIC_BLOCK } from "../../actions";
import cookie from 'react-cookies';
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "" };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    //trigger login in footer menu
    if(!cookie.load('UserId')){
      setTimeout(function(){
      $(document).find('.make_login').attr('href','#login-popup')
      $(document).find('.make_login').addClass('open-popup-link new_login_popup')
      },1000);
    }

    $(document).on('click','.new_login_popup',function(){
      $.magnificPopup.open({
      items: {
      src: '#login-popup'
      },
      type: 'inline'
      });
    });
    //trigger login in footer menu
    
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="footer-top"></div>
        {/*<div className="foot-container">
          <div className="foot-logo">
            <a title="WestLake" href="/">
              <img src={footerLogo} />
            </a>
            <h3>Follow Us</h3>
            <div className="social-foot">
              <ul>
                <li>
                  <a href={void 0}>
                    <img src={facebook} />
                  </a>
                </li>
                <li>
                  <a href={void 0}>
                    <img src={instagram} />
                  </a>
                </li>
                <li>
                  <a href={void 0}>
                    <img src={youtube} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="foot-link">
            <ul>
              <li>
                <a href={void 0}>Home</a>
              </li>
              <li>
                <a href={void 0}>Order Online</a>
              </li>
              <li>
                <a href={void 0}>News & Promotions</a>
              </li>
              <li>
                <a href={void 0}>Reservations</a>
              </li>
              <li>
                <a href={void 0}>About Us</a>
              </li>
              <li>
                <a href={void 0}>My Account</a>
              </li>
            </ul>
          </div>
          <div className="foot-add">
            <div className="foot-add-item">
              <span className="icon">
                <img src={Address} />
              </span>
              <span className="foot-add-txt">
                Blk 4 Queen’s Road
                <br />
                #02-139 <br />
                Singapore 260004
              </span>
            </div>
            <div className="foot-add-item">
              <span className="icon">
                <img src={Address} />
              </span>
              <span className="foot-add-txt">
                Kiosk: #03-89c <br /> Jurong Point
                <br /> Shopping Mall 2
              </span>
            </div>
            <div className="foot-add-item foot-sec">
              <span className="icon">
                <img src={Telephone} />
              </span>
              <a href="tel:+64747283">6474-7283</a>
            </div>
            <div className="foot-add-item foot-sec">
              <span className="icon">
                <img src={Email} />
              </span>
              <a href="mailto:info@westlake.com.sg">info@westlake.com.sg</a>
            </div>
          </div>
          <div className="foot-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.046120007846!2d103.8079778!3d1.3187579!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc2a159b9e5814d60!2sWestlake!5e0!3m2!1sen!2sin!4v1621323995965!5m2!1sen!2sin"
              width="100%"
              height="360"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
  </div>*/}
		
        {this.state.footerlink}
		
        <div className="copyright-section">
		{/*<p>Copyright {yearSp} WestLake All rights reserved.</p>*/}
        <p>Copyright © Westlake 3 Pte Ltd.</p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span />
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
